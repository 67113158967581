body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  height: 100vh;
  overflow: hidden;
}
#root {
  display: flex;
  width: 100%;
  height: 100%;
}

.main-panel {
  padding: 0px;
  box-sizing: border-box;
  position: relative;
  flex: 1;
  overflow: auto;
}

.form-control {
  margin-bottom: 15px;
}
.form-control label {
  display: block;
  margin-bottom: 5px;
}
.form-control input[type="text"],
.form-control select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}
.form-control input[type="radio"],
.form-control input[type="checkbox"] {
  margin-right: 5px;
}
.form-control button {
  width: 100%;
  padding: 10px;
  background-color: #007BFF;
  color: white;
  border: none;
  cursor: pointer;
}
.form-control button:hover {
  background-color: #0056b3;
}



body {
  background-color: #f0f0f0;
  color: #444;
}
a {
  color: #08f;
}
.label {
  color: "black";
  font-family: sans-serif;
  font-size: 12px;
  padding: 2px;
  background: rgba( 0, 0, 0, .6 );
  text-align: center;
  user-select: none;
}

.group_label {
  color: "lightgrey";
  font-family: sans-serif;
  font-style: bold;
  font-size: 10px;
  padding: 0px;
  margin: 0px;
  opacity: 1;
  background: rgba( 0, 0, 0, 1);
  user-select: none;
}